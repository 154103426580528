<template>
  <div>
    <app-timeline>
      <app-timeline-item
        v-if="location && location.id && qrCode"
        icon="SmartphoneIcon"
        :title-size="titleSize"
        :title="`${$t('common.scan_the_qr_code')}`"
        :subtitle="`${$t('point.the_request_form_will_be_opened')}`"
        :subtitle-size="subTitleSize"
      />

      <app-timeline-item
        :title="`${$t('point.enter_the_name_of_the_service_you_need')}`"
        :subtitle="`${$t('Example:')} ${getPurposeTitle('plumber')}`"
        icon="FileTextIcon"
        time=""
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />

      <app-timeline-item
        :title="`${$t('point.briefly_describe_the_service_required')}`"
        :subtitle="`${$t('Example:')} ${$t('Leaking tap in the kitchen')}`"
        icon="FileTextIcon"
        time=""
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />

      <app-timeline-item
        v-if="(!location || !location.id)"
        :title="`${$t('location.specify_location')}`"
        subtitle=""
        icon="MapPinIcon"
        time=""
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />
      <app-timeline-item
        v-else
        :title="`${$t('Address')}`"
        :subtitle="getFormattedAddress(location.address)"
        icon="MapPinIcon"
        time=""
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />
      <app-timeline-item
        :title="`${$t('point.add_photos')} (${$t('common.optionally')})`"
        :subtitle="`${$t('point.this_will_help_the_contractor_estimate_the_time_and_cost_of_the_job')}`"
        icon="ImageIcon"
        time=""
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />
      <app-timeline-item
        v-if="!userId"
        :title="`${$t('common.specify_your_name_and_contact_phone_number')}`"
        :subtitle="userId"
        icon="UserIcon"
        time=""
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />
      <app-timeline-item
        :title="`${$t('common.press_the_button')} '${$t('Add Request')}'`"
        subtitle=""
        icon="CheckCircleIcon"
        time=""
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      />
      <app-timeline-item
        title=""
        subtitle=""
        icon="ClockIcon"
        time=""
        variant="success"
        :title-size="titleSize"
        :subtitle-size="subTitleSize"
      >
        <p :class="subTitleSize">
          {{ $t('common.our_manager_or_the_service_provider_directly_will_contact_you_shortly') }}
        </p>
      </app-timeline-item>
    </app-timeline>
    <location-q-rcode
      v-if="location && location.id && qrCode"
      :location="location"
      class="mt-3"
    />
  </div>

</template>

<script>

import Ripple from 'vue-ripple-directive'
import LocationQRcode from '@/views/apps/location/details/LocationQRcode.vue'
import AppTimeline from '@/layouts/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@/layouts/components/app-timeline/AppTimelineItem.vue'
import { serviceOptions } from '@/mixins/options'
import { getFormattedAddress } from '@/mixins/functions'

export default {
  components: {
    LocationQRcode,
    AppTimelineItem,
    AppTimeline,

  },
  directives: {
    Ripple,
  },
  mixins: [getFormattedAddress],
  props: {
    location: {
      type: Object,
      required: false,
      default: () => {},
    },
    userId: {
      type: Number,
      required: false,
      default: () => null,
    },
    titleSize: {
      type: String,
      default: 'h6',
    },
    subTitleSize: {
      type: String,
      default: 'h6',
    },
    qrCode: {
      type: Boolean,
      required: false,
      default: () => true,
    },

  },
  data() {
    return {
    }
  },

  methods: {

  },
  setup() {
    const { getPurposeTitle } = serviceOptions

    return {
      getPurposeTitle,
    }
  },
}

</script>
<style lang="scss" scoped>
.timeline-item:not(:last-of-type) {
  padding-bottom: 1.2rem!important;
}
</style>
