import { firebase } from '@/views/apps/notification/firebase/firebase'
import store from '@/store'
import { ref, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'

export const pushNotification = () => {
  const { createFcmSubscription, getMessage } = firebase()

  const notificationsSupported = ('Notification' in window && 'serviceWorker' in navigator)

  const notificationsPermissions = ref('')

  const notificationsEnabled = computed(() => {
    if (notificationsSupported) {
      notificationsPermissions.value = Notification.permission
    }
    return (notificationsPermissions.value === 'granted')
  })

  const requestPermission = () => {
    if (notificationsSupported) {
      Notification.requestPermission()
        .then(result => {
          notificationsPermissions.value = result
        })
    }
  }
  return {
    createFcmSubscription,
    getMessage,
    notificationsSupported,
    notificationsEnabled,
    requestPermission,
    notificationsPermissions,
  }
}

export const webNotification = () => {
  // Pagination count <= required by pagination component
  const totalNotifications = ref(null)
  const readNotification = notification => {
    if (!notification.read_at) {
      store.dispatch('notification/readNotification', notification.id)
        .then(response => {
          const successStatuses = [200, 201]
          if (successStatuses.includes(response.status)) {
            const notificationsArr = store.getters['notification/notifications']
            const index = notificationsArr.findIndex(n => n.id === notification.id)
            notificationsArr[index].read_at = response.data.read_at
            store.commit('notification/DECREASE_NOTIFICATIONS_UNREAD_QTY', 1)
          }
        })
    }
  }
  const submittingFetchNotifications = ref(false)
  const fetchNotifications = (...args) => {
    submittingFetchNotifications.value = true
    store.dispatch('notification/fetchNotifications', ...args).then(response => {
      submittingFetchNotifications.value = false
      const { total } = response.data.meta
      totalNotifications.value = total
    })
  }

  const submittingReadAll = ref(false)
  const readAllNotifications = () => {
    submittingReadAll.value = true
    store.dispatch('notification/readAllNotifications').then(response => {
      if (response.status === 200) {
        const notificationsArr = store.getters['notification/notifications']
        const date = new Date().toISOString()
        const updatedData = notificationsArr.map(n => (n.read_at == null ? { ...n, read_at: date } : n))
        store.commit('notification/SET_NOTIFICATIONS', updatedData)
        store.commit('notification/DECREASE_NOTIFICATIONS_UNREAD_QTY', store.getters['notification/notificationsUnreadQty'])
      }
      submittingReadAll.value = false
    })
  }

  const isNotificationUnread = notification => !('read_at' in notification) || notification.read_at === null

  const isReferralsNotification = notification => ('referral_id' in notification.data) && notification.data.referral_id !== null

  return {
    totalNotifications,
    submittingFetchNotifications,
    fetchNotifications,
    submittingReadAll,
    readAllNotifications,
    readNotification,
    isNotificationUnread,
    isReferralsNotification,
  }
}

export const notificationFiltersSortingAndPagination = () => {
  const filters = ref({
    unread: 'unread',
    page: 1,
    perPage: 6,
    order: 'desc',
  })

  const filterOptions = {
    perPage: [6, 9, 24, 72],
  }

  // Sorting
  const sortBy = ref({ text: 'Submit date', value: 'submit_date' })
  const sortByOptions = [
    { text: 'Submit date', value: 'submit_date' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const manageNotificationActions = () => {
  const { router } = useRouter()
  const { readNotification } = webNotification()
  const getReferralId = notification => notification.data.referral_id
  const openEvent = notification => {
    router.push({ name: 'event-calendar-edit', params: { id: notification.data.event_id } }).then(() => {
      readNotification(notification)
    })
  }
  const getRequestRoute = notification => {
    let name = 'apps-search-service-request-details'
    const types = [
      'EventWithdrawnNotification',
      'EventCreatedNotification',
      'EventUpdatedNotification',
      'ServiceRequestCreatedNotification',
      'EventDeletedNotification',
    ]

    if (types.includes(notification.type) || getReferralId(notification)) {
      name = 'apps-service-request-info'
    }

    return { name, params: { id: notification.data.service_request_id }, hash: '#proposals' }
  }

  return {
    openEvent,
    getReferralId,
    readNotification,
    getRequestRoute,
  }
}

export const notificationOptions = () => {
  const notificationTypes = {
    ServiceRequestCreatedNotification: {
      icon: 'PhoneCallIcon',
      title: 'New Request',
      variant: 'primary',
    },
    DirectServiceRequestCreatedNotification: {
      icon: 'StarIcon',
      title: 'point.direct_request',
      variant: 'warning',
    },
    EventCreatedNotification: {
      icon: 'MessageSquareIcon',
      title: 'New Proposal',
      variant: 'primary',
    },
    EventAcceptedNotification: {
      icon: 'ThumbsUpIcon',
      title: 'Proposal Accepted',
      variant: 'success',
    },
    EventWithdrawnNotification: {
      icon: 'RotateCwIcon',
      title: 'Proposal Withdrawn',
      variant: 'warning',
    },
    EventUpdatedNotification: {
      icon: 'RefreshCcwIcon',
      title: 'Proposal Updated',
      variant: 'warning',
    },
    EventDeclinedNotification: {
      icon: 'ThumbsDownIcon',
      title: 'Proposal Declined',
      variant: 'danger',
    },
    EventDeletedNotification: {
      icon: 'Trash2Icon',
      title: 'Proposal Deleted',
      variant: 'danger',
    },
  }
  return {
    notificationTypes,
  }
}
