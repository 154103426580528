<template>
  <b-alert
    show
    variant="primary"
  >
    <h4
      v-if="headerText"
      class="alert-heading"
    >
      {{ headerText }}
    </h4>
    <div
      class="alert-body"
    >
      <feather-icon
        icon="InfoIcon"
        class="mr-50"
      />
      <span>{{ $t('subscription.you_can_see_the_tariff_plans_and_buy_a_subscription_on_the_page') }}
        <strong><b-link :to="{path:`/pricing` }">{{ $t('Pricing') }}</b-link></strong>
      </span>
    </div>
  </b-alert>
</template>

<script>
import {
  BAlert, BLink,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'

export default {
  components: {
    BAlert,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    headerText: {
      type: String,
      default: () => i18n.t('errors.data.ability_not_granted'),
      required: false,
    },
  },

  setup() {
    return {}
  },
}

</script>

<style lang="scss" scoped>

</style>
