<template>
  <b-card
    no-body
  >
    <b-card-header>
      <b-card-title v-if="title">
        <div class="d-flex">
          <feather-icon
            icon="PhoneCallIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            {{ $t('Overview') }}
          </h4>
        </div>
      </b-card-title>
    </b-card-header>
    <b-card-body>
      <!-- form -->
      <b-row>
        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            :label="$t('Offer')"
            label-for="pointPurposes"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Offer')"
              rules="required"
            >
              <v-select
                id="pointPurposes"
                ref="pointPurposes"
                v-model="pointPurposes"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('point.which_service_do_you_require?')"
                label="item_data"
                multiple
                :filterable="optionsPointPurposesLimited"
                :selectable="() => pointPurposes.length < 4"
                :options="optionsPointPurposes"
                :loading="fetchingPointPurposes"

                @search="(search, loading) => {
                  if(search.length > 2 && !optionsPointPurposesLimited){
                    loading(true)
                    onSearchDebounced(search, loading)}
                }
                "
              >

                <template #option="{ slug, point_type }">
                  <div class="text-wrap">
                    <strong> {{ getPurposeTitle(slug) }}</strong>
                  </div>
                  <small> ({{ getTypeTitle(point_type.slug) }})</small>
                </template>
                <template #selected-option="{ slug }">
                  <div class="text-wrap">
                    {{ getPurposeTitle(slug) }}
                  </div>
                </template>
                <template v-slot:no-options="{ search, searching,loading }">
                  <span />
                  <em
                    v-if="search.length && !loading"
                    style="opacity: 0.5"
                  >{{ $t('common.no_results_found_for') }}: {{ search }}</em>
                  <em
                    v-else
                    style="opacity: 0.5"
                  > {{ $t('common.start_typing_to_search') }}</em>

                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            :label="$t('Language')"
            label-for="pointLanguage"
          >
            <v-select
              id="pointLanguage"
              :value="pointData.lang=$i18n.locale"
              :options="optionLanguages"
              :reduce="(text) => text.label"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              disabled
              :clearable="false"
              :no-drop="true"
            >
              <template #option="{ name }">
                <span> {{ name }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <b-form-group
            :label="$t('Title')"
            label-for="title"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Title')"
              rules="required|max:150"
            >
              <b-form-textarea
                id="title"
                v-model="pointData.name"
                :state="errors.length > 0 ? false:null"
                rows="1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            :label="$t('Description')"
            label-for="description"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Description')"
              rules="max:1000"
            >
              <b-form-textarea
                id="description"
                v-model="pointData.description"
                rows="1"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormGroup, BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import {
  required, confirmed, min,
} from '@validations'
import vSelect from 'vue-select'
import { locales, serviceOptions } from '@/mixins/options'
import { ref, watch } from '@vue/composition-api'
import { usePointRemoteData } from '@/views/apps/service/usePoints'
import i18n from '@/libs/i18n'
import { useDebounceFn } from '@vueuse/core'

export default {
  components: {
    ValidationProvider,
    vSelect,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BFormTextarea,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
    title: {
      type: Boolean,
      required: false,
      default: () => true,
    },
  },
  data() {
    return {
      pointData: this.data,
      pointPurposes: [],
      required,
      confirmed,
      min,
    }
  },

  mounted() {
    this.$watch('data.id', () => {
      this.pointData = this.data
      this.fetchFilteredPurposesByPointType(this.data.point_type.id).then(() => {
        this.pointData.point_purpose_ids = this.data.point_purposes.map(el => el.id)
        this.pointPurposes = this.optionsPointPurposes.filter(el => this.pointData.point_purpose_ids.includes(el.id))
      })
    })
    this.$watch('pointPurposes', (newValue, oldValue) => {
      if (!newValue.length) return

      const lastAddedPurpose = newValue.slice(-1)[0]

      // Delete all purposes that are of a different point type
      if (oldValue.length) {
        const previousAddedPurpose = oldValue.slice(-1)[0]

        if (previousAddedPurpose.point_type.id !== lastAddedPurpose.point_type.id) {
          this.pointPurposes = this.pointPurposes.filter(el => el.point_type.id !== previousAddedPurpose.point_type.id)
          this.fetchFilteredPurposesByPointType(lastAddedPurpose.point_type.id)
        }
      } else if (!this.pointData.id) {
        this.fetchFilteredPurposesByPointType(lastAddedPurpose.point_type.id)
      }

      this.pointData.point_type_id = lastAddedPurpose.point_type.id
      this.pointData.point_purpose_ids = this.pointPurposes.map(el => el.id)
    })
  },

  setup(props) {
    const optionsPointPurposes = ref([])
    const fetchingPointPurposes = ref(false)
    const optionsPointPurposesLimited = ref(false)
    const {
      localesList,
    } = locales()
    const optionLanguages = localesList

    const {
      getTypeTitle, getTranslatedPointPurposes, getPurposeTitle,
    } = serviceOptions
    const { fetchPointPurposes } = usePointRemoteData()

    const setOptionsPointPurposes = async (args, data) => {
      // Remove all the point_purposes with same id
      const optionsList = [...new Map(data.map(item => [item.id, item])).values()]

      optionsList.map(el => {
        el.item_data = [getPurposeTitle(el.slug), args.q].join(' ')
        return el.item_data
      })
      optionsPointPurposes.value = await getTranslatedPointPurposes(optionsList)
      return optionsPointPurposes.value
    }

    async function fetchFilteredPurposesByPointType(pointTypeIds) {
      if (optionsPointPurposesLimited.value) return

      fetchingPointPurposes.value = true
      const args = {
        point_type_ids: pointTypeIds,
        lang: i18n.locale,
      }
      return new Promise((resolve, reject) => fetchPointPurposes(args)
        .then(async response => {
          await setOptionsPointPurposes(args, response.data.data)
          resolve(true)
        })
        .catch(error => reject(error)).finally(() => {
          fetchingPointPurposes.value = false
        }))
    }

    const onSearchDebounced = useDebounceFn(async (search, loading) => {
      const args = {
        q: search,
        lang: i18n.locale,
      }

      return fetchPointPurposes(args)
        .then(async response => setOptionsPointPurposes(args, response.data.data))
        .catch(error => console.log(error)).finally(() => loading(false))
    }, 1000)

    if (props.user) {
      watch(props.user, val => {
        const serviceOffers = val.service_offers
        if (serviceOffers) {
          optionsPointPurposesLimited.value = true
          const servicePurposes = []
          serviceOffers.forEach(offer => {
            const purposes = offer.point_purposes
            purposes.forEach(el => {
              const purpose = el
              purpose.point_type = offer.point_type
              servicePurposes.push(purpose)
            })
          })

          setOptionsPointPurposes({}, servicePurposes)
        }
      }, { immediate: true })
    }

    return {
      getTranslatedPointPurposes,
      optionsPointPurposes,
      optionsPointPurposesLimited,
      fetchFilteredPurposesByPointType,
      fetchingPointPurposes,
      getTypeTitle,
      getPurposeTitle,
      optionLanguages,
      onSearchDebounced,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
