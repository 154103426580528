import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { GlobalEventEmitter } from '@/utils/GlobalEventEmitter'
import store from '@/store'

export const firebase = () => {
  // Web app's Firebase configuration
  const firebaseConfig = {
    apiKey: 'AIzaSyAAfjSGEK7ZW3sePRcM38NVyBuW3jMvfno',
    authDomain: 'onetimejob-5f9c1.firebaseapp.com',
    projectId: 'onetimejob-5f9c1',
    storageBucket: 'onetimejob-5f9c1.appspot.com',
    messagingSenderId: '114675673731',
    appId: '1:114675673731:web:b75545dcb0be45f22c89b6',
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig)

  // Retrieve firebase messaging
  const messaging = getMessaging()

  const getMessage = () => onMessage(messaging, payload => {
    GlobalEventEmitter.$emit('newMessageReceived', payload)
  })

  const setFcmToken = token => {
    localStorage.setItem('_fcmToken', token)
  }

  const getFcmToken = () => localStorage.getItem('_fcmToken')

  const replaceFcmToken = (oldToken, newToken) => store.dispatch('notification/replaceFcmToken',
    { oldToken, newToken })
    .then(() => {
      setFcmToken(newToken)
    })
    .catch(err => {
      console.log('An error occurred while adding token. ', err)
    })

  const addFcmToken = token => store.dispatch('notification/addFcmToken',
    { token, topic: 'requests' })
    .then(() => {
      setFcmToken(token)
    }).catch(err => {
      console.log('An error occurred while adding token. ', err)
    })

  const createFcmSubscription = async () => getToken(messaging, { vapidKey: process.env.VUE_APP_VAPID_PUBLIC_KEY })
    .then(token => {
      if (token) {
        addFcmToken(token)
        // const oldToken = getFcmToken()
        // if (!oldToken) {
        //   addFcmToken(token)
        //   return
        // }
        // if (token !== oldToken) {
        //   replaceFcmToken(oldToken, token)
        // }
      }
    }).catch(err => {
      console.log('An error occurred while retrieving token. ', err)
    })

  return {
    createFcmSubscription,
    getMessage,
  }
}
