<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="primary"
    class="text-uppercase"
    block
    @click="$router.push({ name: 'apps-service-request-add-wizard'})"
  >
    <feather-icon
      icon="PhoneCallIcon"
      class="mr-50"
    />
    <span class="align-middle">
      {{ $t('Request services') }}
    </span>
  </b-button>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
  },

  setup() {
    return {
    }
  },
}
</script>
