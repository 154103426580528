<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <!-- pricing plan cards -->

    <b-row class="pricing-card">
      <b-col
        md="12"
        class="mx-auto"
      >
        <b-row
          v-if="horizontal"
          class="match-height"
        >
          <b-col
            v-for="(plan, index) in pricing"
            :key="index"
            cols="12"
          >
            <b-card
              no-body
              class="overflow-hidden"

              :border-variant="`${(plan.popular)?resolveCardVariant(plan):''}`"
            >
              <b-row
                no-gutters
                class="match-height"
              >
                <b-col md="6">
                  <div
                    class="rounded-top text-center h-100"
                    :class="`bg-light-${resolveCardVariant(plan)}`"
                  >
                    <div
                      class="pricing-badge text-right p-1"
                    >
                      <b-badge
                        v-if="plan.badge"
                        :variant="`light-${resolveCardVariant(plan)}`"
                        pill
                      >
                        {{ $t(plan.badge) }}
                      </b-badge>
                    </div>
                    <!-- img -->
                    <div class="height-140 d-flex justify-content-center align-content-end pb-2">
                      <b-img
                        v-if="plan.img"
                        :src="plan.img"
                        :height="132"
                        :alt="plan.title"
                      />
                    </div>

                    <!--/ img -->
                    <h3 class="text-uppercase">
                      {{ $t(plan.title) }}
                    </h3>
                    <b-card-text class="font-weight-bolder">
                      {{ $t(plan.subtitle) }}
                    </b-card-text>

                    <!-- annual plan -->
                    <div class="annual-plan">
                      <div class="plan-price mt-2">
                        <sup
                          :class="`text-${resolveCardVariant(plan)}`"
                          class="font-medium-1 font-weight-bold"
                        >{{ getCurrencySymbol() }}</sup>
                        <span
                          :class="`text-${resolveCardVariant(plan)}`"
                          class="pricing-basic-value font-weight-bolder"
                        >{{ monthlyPlanShow ? plan.monthlyPrice:pricing.yearlyPlan.perMonth }}</span>
                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ $t('month') }}</sub>
                      </div>
                      <small
                        v-show="!monthlyPlanShow"
                        class="annual-pricing text-muted"
                      >USD {{ plan.yearlyPlan.totalAnnual }} / {{ $t('year') }}</small>
                    </div>
                    <!--/ annual plan -->
                  </div>
                </b-col>
                <b-col md="6">
                  <b-card-body>
                    <div class="align-items-center">
                      <!-- plan feature -->

                      <div
                        v-for="(feature,index) in plan.planFeatures"
                        :key="index"
                      >
                        <div class="d-flex justify-content-between align-items-center">
                          <span>{{ $t(feature.name) }}</span>

                          <span>
                            <span v-if="feature.qtyMin"><b>{{ feature.qtyMin }}</b><small
                              v-if="feature.per"
                              class="text-lowercase"
                            > / {{ $t(feature.per) }}</small></span>
                            <span v-if="feature.qtyMax"><b>{{ feature.qtyMax }}</b><small
                              v-if="feature.per"
                              class="text-lowercase"
                            > / {{ $t(feature.per) }} </small></span>
                            <b-avatar
                              v-if="feature.value && feature.value==='yes'"
                              variant="success"
                              size="sm"
                            >
                              <feather-icon
                                icon="CheckIcon"
                                size="14"
                              />
                            </b-avatar>
                            <b-avatar
                              v-if="feature.value && feature.value==='no'"
                              variant="danger"
                              size="sm"
                            >
                              <feather-icon
                                icon="XIcon"
                                size="14"
                              />
                            </b-avatar>
                            <span v-if="feature.value && !['yes','no'].includes(feature.value)"><b>{{ $t(feature.value) }}</b></span>
                          </span>

                        </div>
                        <hr>
                      </div>

                      <!--/ plan feature -->

                    </div>
                  </b-card-body>
                  <b-card-footer>
                    <!-- buttons -->

                    <div v-if="plan.type==='basic'">
                      <request-add-wizard-button v-if="target==='customer'" />
                      <offer-add-wizard-button v-else />
                    </div>
                    <div v-else>
                      <a :href="upgradePlanLink(plan.title)">
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          block
                          :variant="`${resolveButtonVariant(plan)}`"
                          class="text-uppercase"
                        >
                          <feather-icon
                            icon="TrendingUpIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">
                            {{ $t('subscription.upgrade_plan') }}
                          </span>
                        </b-button>
                      </a>
                    </div>
                  </b-card-footer>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row
          v-else
          class="match-height"
        >
          <b-col
            v-for="(plan, index) in pricing"
            :key="index"
            :sm="pricing.length>1?'4':'12'"
          >
            <b-card
              :border-variant="`${(plan.popular)?resolveCardVariant(plan):''}`"
              no-body
            >
              <div
                class="rounded-top text-center"
                :class="`bg-light-${resolveCardVariant(plan)}`"
              >
                <div
                  class="pricing-badge text-right p-1"
                >
                  <b-badge
                    v-if="plan.badge"
                    :variant="`light-${resolveCardVariant(plan)}`"
                    pill
                  >
                    {{ $t(plan.badge) }}
                  </b-badge>
                </div>
                <!-- img -->
                <div class="height-140 d-flex justify-content-center align-content-end pb-2">
                  <b-img
                    v-if="plan.img"
                    :src="plan.img"
                    :height="132"
                    :alt="plan.title"
                  />
                </div>

                <!--/ img -->
                <h3 class="text-uppercase">
                  {{ $t(plan.title) }}
                </h3>
                <b-card-text class="font-weight-bolder">
                  {{ $t(plan.subtitle) }}
                </b-card-text>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup
                      :class="`text-${resolveCardVariant(plan)}`"
                      class="font-medium-1 font-weight-bold"
                    >{{ getCurrencySymbol(plan.currency) }}</sup>
                    <span
                      :class="`text-${resolveCardVariant(plan)}`"
                      class="pricing-basic-value font-weight-bolder"
                    >{{ monthlyPlanShow ? plan.monthlyPrice:pricing.yearlyPlan.perMonth }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ $t('month') }}</sub>
                  </div>
                  <small
                    v-show="!monthlyPlanShow"
                    class="annual-pricing text-muted"
                  >USD {{ plan.yearlyPlan.totalAnnual }} / {{ $t('year') }}</small>
                </div>
                <!--/ annual plan -->
              </div>
              <b-card-body>
                <div class="align-items-center">
                  <!-- plan feature -->

                  <div
                    v-for="(feature,index) in plan.planFeatures"
                    :key="index"
                  >
                    <div class="d-flex justify-content-between align-items-center">
                      <span>{{ $t(feature.name) }}</span>

                      <span>
                        <span v-if="feature.qtyMin"><b>{{ feature.qtyMin }}</b><small
                          v-if="feature.per"
                          class="text-lowercase"
                        > / {{ $t(feature.per) }}</small></span>
                        <span v-if="feature.qtyMax"><b>{{ feature.qtyMax }}</b><small
                          v-if="feature.per"
                          class="text-lowercase"
                        > / {{ $t(feature.per) }} </small></span>
                        <b-avatar
                          v-if="feature.value && feature.value==='yes'"
                          variant="success"
                          size="sm"
                        >
                          <feather-icon
                            icon="CheckIcon"
                            size="14"
                          />
                        </b-avatar>
                        <b-avatar
                          v-if="feature.value && feature.value==='no'"
                          variant="danger"
                          size="sm"
                        >
                          <feather-icon
                            icon="XIcon"
                            size="14"
                          />
                        </b-avatar>
                        <span v-if="feature.value && !['yes','no'].includes(feature.value)"><b>{{ $t(feature.value) }}</b></span>
                      </span>

                    </div>
                    <hr>
                  </div>

                  <!--/ plan feature -->

                </div>
              </b-card-body>
              <b-card-footer>
                <!-- buttons -->

                <div v-if="plan.type==='basic'">
                  <request-add-wizard-button v-if="plan.target ==='customer'" />

                  <offer-add-wizard-button v-if="plan.target==='contractor' && canAddOffer" />
                  <div v-if="plan.target==='contractor' && !canAddOffer">
                    <subscribe-button
                      :plan-title="plan.title"
                      :variant="resolveButtonVariant(plan)"
                    />
                  </div>
                </div>
                <div v-else>
                  <subscribe-button
                    :plan-title="plan.title"
                    :variant="resolveButtonVariant(plan)"
                  />
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BImg, BCardText, BButton, BBadge, BCardBody, BAvatar, BCardFooter,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { computed, ref, watch } from '@vue/composition-api'
import {
  currency,
} from '@/mixins/options'
import ScrollTo from 'vue-scrollto'
import store from '@/store'
import { useUserUi } from '@/views/apps/user/useUser'
import OfferAddWizardButton from '@/views/apps/service/offer/edit/OfferAddWizardButton.vue'
import RequestAddWizardButton from '@/views/apps/service/request/edit/RequestAddWizardButton.vue'
import { usePointsUi } from '@/views/apps/service/usePoints'
import { usePlans } from '@/views/apps/subscription/useSubscription'
import SubscribeButton from '@/views/apps/subscription/SubscribeButton.vue'

export default {
  components: {
    BButton,
    BCardText,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BCardBody,
    BAvatar,
    BCardFooter,
    OfferAddWizardButton,
    RequestAddWizardButton,
    SubscribeButton,
  },
  directives: {
    Ripple,
    ScrollTo,
  },
  props: {
    subscriptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    horizontal: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    target: {
      type: String,
      required: false,
      default: () => '',
    },

  },
  data() {
    return {
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
      plansQuantity: null,
    }
  },

  created() {
    this.toggleUserPlan(this.plan)
  },
  methods: {
    resolveCardVariant(plan) {
      return plan.target === 'contractor' ? 'warning' : 'primary'
    },
    resolveButtonVariant(plan) {
      const variant = this.resolveCardVariant(plan)
      return plan.popular ? variant : 'outline-'.concat(variant)
    },
    // toggleMonthlyPlan() {
    //   this.monthlyPlanShow = this.status === 'monthly'
    // },
    toggleUserPlan(user) {
      if ('contractor' in this.$route.query) {
        store.state.appLanding.userRole = 'contractor'
      }

      if (user) {
        store.state.appLanding.userRole = user
      }

      // const plans = this.pricingPlans.find(value => value[store.state.appLanding.userRole])
      this.pricing = this.pricingPlans
      this.plansQuantity = Object.keys(this.pricing).length
    },
  },
  setup(props) {
    const pricingPlans = ref([])
    pricingPlans.value = [
      {
        title: 'subscription.user',
        slug: 'user',
        type: 'basic',
        currency: 'EUR',
        popular: false,
        img: require('@/assets/images/svg/user.svg'),
        subtitle: 'subscription.a_simple_start_for_everyone',
        monthlyPrice: 0,
        target: 'customer',
        yearlyPlan: {
          perMonth: 0,
          totalAnnual: 0,
        },
        planFeatures: [
          { name: 'Service Requests', value: 'subscription.unlimited' },
          { name: 'Service Offers', value: 'no' },
          // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
          { name: 'location.locations', qtyMax: 2 },
          { name: 'Calendar of events', value: 'yes' },
          { name: 'point.service_finder', value: 'yes' },
          { name: 'point.request_finder', value: 'no' },
          { name: 'common.personal_manager', value: 'no' },
          { name: 'referral.referrals', value: 'no' },
          { name: 'subscription.cashback', value: 'no' },
          { name: 'Dashboards', value: 'no' },
        // 'Search for contractors and provide services at the same time',
        // 'Unlimited amount of proposals on services requests',
        // 'Unlimited number of requests for services',
        // 'Unlimited number of service offers',
        // 'Calendar of events',
        ],
        badge: '',
      },
      {
        title: 'subscription.landlord',
        slug: 'landlord',
        type: 'standard',
        currency: 'EUR',
        popular: true,
        img: require('@/assets/images/svg/landlord.svg'),
        subtitle: 'subscription.for_property_owners',
        monthlyPrice: 29,
        target: 'customer',
        yearlyPlan: {
          perMonth: 0,
          totalAnnual: 0,
        },
        planFeatures: [
          { name: 'Service Requests', value: 'subscription.unlimited' },
          { name: 'Service Offers', value: 'no' },
          // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
          { name: 'location.locations', qtyMax: 10 },
          { name: 'Calendar of events', value: 'yes' },
          { name: 'point.service_finder', value: 'yes' },
          { name: 'point.request_finder', value: 'no' },
          { name: 'common.personal_manager', value: 'yes' },
          { name: 'referral.referrals', value: 'yes' },
          { name: 'subscription.cashback', value: 'no' },
          { name: 'Dashboards', value: 'no' },
        // 'Search for contractors and provide services at the same time',
        // 'Unlimited amount of proposals on services requests',
        // 'Unlimited number of requests for services',
        // 'Unlimited number of service offers',
        // 'Calendar of events',
        ],
        badge: 'subscription.popular',
      },
      {
        title: 'subscription.agency',
        slug: 'agency',
        type: 'enterprise',
        currency: 'EUR',
        popular: false,
        img: require('@/assets/images/svg/agency.svg'),
        subtitle: 'subscription.for_small_and_medium_sized_businesses',
        monthlyPrice: 99,
        target: 'customer',
        yearlyPlan: {
          perMonth: 0,
          totalAnnual: 0,
        },
        planFeatures: [
          { name: 'Service Requests', value: 'subscription.unlimited' },
          { name: 'Service Offers', value: 'no' },
          // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
          { name: 'location.locations', qtyMax: 30 },
          { name: 'Calendar of events', value: 'yes' },
          { name: 'point.service_finder', value: 'yes' },
          { name: 'point.request_finder', value: 'no' },
          { name: 'common.personal_manager', value: 'yes' },
          { name: 'referral.referrals', value: 'yes' },
          { name: 'subscription.cashback', value: 'yes' },
          { name: 'Dashboards', value: 'no' },
        // 'Search for contractors and provide services at the same time',
        // 'Unlimited amount of proposals on services requests',
        // 'Unlimited number of requests for services',
        // 'Unlimited number of service offers',
        // 'Calendar of events',
        ],
      },
      {
        title: 'subscription.freelancer',
        slug: 'freelancer',
        type: 'basic',
        currency: 'EUR',
        popular: false,
        img: require('@/assets/images/svg/freelancer.svg'),
        subtitle: 'subscription.a_simple_start_for_everyone',
        monthlyPrice: 10,
        target: 'contractor',
        yearlyPlan: {
          perMonth: 0,
          totalAnnual: 0,
        },
        planFeatures: [
          { name: 'Service Requests', value: 'subscription.unlimited' },
          { name: 'Service Offers', value: 'yes' },
          { name: 'Purposes', qtyMax: 1 },
          // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
          { name: 'location.locations', qtyMax: 1 },
          { name: 'Calendar of events', value: 'yes' },
          { name: 'point.service_finder', value: 'yes' },
          { name: 'point.request_finder', value: 'yes' },
          { name: 'common.personal_manager', value: 'no' },
          { name: 'referral.referrals', value: 'no' },
          { name: 'subscription.cashback', value: 'no' },
          { name: 'Dashboards', value: 'no' },
        // 'Search for contractors and provide services at the same time',
        // 'Unlimited amount of proposals on services requests',
        // 'Unlimited number of requests for services',
        // 'Unlimited number of service offers',
        // 'Calendar of events',
        ],
        badge: 'subscription.first_month_free_of_charge',
      },
      {
        title: 'subscription.professional',
        slug: 'professional',
        type: 'standard',
        currency: 'EUR',
        popular: true,
        img: require('@/assets/images/svg/professional.svg'),
        subtitle: 'subscription.for_self_employed_contractors',
        monthlyPrice: 49,
        target: 'contractor',
        yearlyPlan: {
          perMonth: 0,
          totalAnnual: 0,
        },
        planFeatures: [
          { name: 'Service Requests', value: 'subscription.unlimited' },
          { name: 'Service Offers', value: 'yes' },
          { name: 'Purposes', qtyMax: 4 },
          // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
          { name: 'location.locations', qtyMax: 4 },
          { name: 'Calendar of events', value: 'yes' },
          { name: 'point.service_finder', value: 'yes' },
          { name: 'point.request_finder', value: 'yes' },
          { name: 'common.personal_manager', value: 'yes' },
          { name: 'referral.referrals', value: 'yes' },
          { name: 'subscription.cashback', value: 'yes' },
          { name: 'Dashboards', value: 'no' },
        // 'Search for contractors and provide services at the same time',
        // 'Unlimited amount of proposals on services requests',
        // 'Unlimited number of requests for services',
        // 'Unlimited number of service offers',
        // 'Calendar of events',
        ],
        badge: 'subscription.popular',
      },
      {
        title: 'subscription.enterprise',
        slug: 'enterprise',
        type: 'enterprise',
        currency: 'EUR',
        popular: false,
        img: require('@/assets/images/svg/enterprise.svg'),
        subtitle: 'subscription.for_small_and_medium_sized_businesses',
        monthlyPrice: 299,
        target: 'contractor',
        yearlyPlan: {
          perMonth: 0,
          totalAnnual: 0,
        },
        planFeatures: [
          { name: 'Service Requests', value: 'subscription.unlimited' },
          { name: 'Service Offers', value: 'yes' },
          { name: 'Purposes', qtyMax: 8 },
          // {name:'contractors_contacts', qtyMax:3, per:'subscription.day'},
          { name: 'location.locations', qtyMax: 8 },
          { name: 'Calendar of events', value: 'yes' },
          { name: 'point.service_finder', value: 'yes' },
          { name: 'point.request_finder', value: 'yes' },
          { name: 'common.personal_manager', value: 'yes' },
          { name: 'referral.referrals', value: 'yes' },
          { name: 'subscription.cashback', value: 'yes' },
          { name: 'Dashboards', value: 'yes' },
        // 'Search for contractors and provide services at the same time',
        // 'Unlimited amount of proposals on services requests',
        // 'Unlimited number of requests for services',
        // 'Unlimited number of service offers',
        // 'Calendar of events',
        ],
        badge: '',
      },
    ]

    const { isUserServiceProvider } = useUserUi()

    const { getCurrencySymbol } = currency()

    watch(() => props.target, target => {
      if (!target.length) return
      pricingPlans.value = pricingPlans.value.filter(el => el.target === target)
    }, { immediate: true })

    watch(() => props.subscriptions, subscriptions => {
      if (!subscriptions.length) return

      const plans = []
      subscriptions.forEach(obj => {
        const index = pricingPlans.value.findIndex(el => el.slug === obj.label.toLowerCase())

        if (index !== -1) {
          if (obj.price) {
            pricingPlans.value[index].monthlyPrice = obj.price
          }
          if (obj.currency) {
            pricingPlans.value[index].currency = obj.currency
          }

          plans.push(pricingPlans.value[index])
        }
      })
      pricingPlans.value = plans
    }, { immediate: true })

    const {
      userCanAddOffer,
    } = usePointsUi()

    const {
      upgradePlanLink,
    } = usePlans()

    const canAddOffer = computed(() => userCanAddOffer())

    return {
      pricingPlans,
      getCurrencySymbol,
      isUserServiceProvider,
      canAddOffer,
      upgradePlanLink,
    }
  },
}

</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-pricing.scss';
.pricing-card .card-body>img{
  height: 96px!important;
}
.card .card-footer{
  border-top: none!important;
}
.dark-layout .pricing-free-trial {
  background-color: #283046;
}
.pricing-badge{
  min-height: 4rem;
}
</style>
