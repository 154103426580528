export const usePlans = () => {
  const upgradePlanLink = plan => {
    const tel = encodeURIComponent('+').concat('34603334187')
    const link = 'https://wa.me/'.concat(tel)
    const url = new URL(link)
    url.searchParams.append('text', plan)
    return url.href
  }

  return {
    upgradePlanLink,
  }
}
