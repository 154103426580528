<template>
  <a
    v-if="planTitle"
    :href="upgradePlanLink(planTitle)"
  >
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      block
      :variant="variant"
      class="text-uppercase"
    >
      <feather-icon
        icon="TrendingUpIcon"
        class="mr-50"
      />
      <span class="align-middle">
        {{ $t('subscription.upgrade_plan') }}
      </span>

    </b-button>
  </a>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { usePlans } from '@/views/apps/subscription/useSubscription'

export default {
  components: {
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    variant: {
      type: String,
      required: false,
      default: () => 'primary',
    },
    planTitle: {
      type: String,
      required: true,
      default: () => '',
    },
  },

  setup() {
    const {
      upgradePlanLink,
    } = usePlans()

    return {
      upgradePlanLink,
    }
  },
}

</script>

<style lang="scss" scoped>

</style>
