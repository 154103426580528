<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="warning"
      class="text-uppercase"
      :class="!canAddOffer?'disabled':''"
      block
      @click="addOffer()"
    >
      <feather-icon
        icon="BriefcaseIcon"
        class="mr-50"
      />
      <span class="align-middle">
        {{ $t('Offer services') }}
      </span>
    </b-button>
    <b-modal
      id="modal-form"
      ref="modal-form"
      v-model="displayModal"
      centered
      hide-footer
      :static="true"
    >
      <!-- Modal Form Header -->
      <template #modal-header>

        <b-button
          class="close btn-icon"
          variant="outline-secondary"
          size="sm"
          @click="displayModal=false"
        >
          <feather-icon
            icon="XIcon"
            size="26"
          />
        </b-button>

      </template>
      <!-- Modal Form Body -->
      <!--Form-->
      <b-container
        fluid
      >
        <subscription-alert />
        <!--        <b-button-->
        <!--          v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
        <!--          variant="outline-primary"-->
        <!--          block-->
        <!--          @click="$router.push({path:`/pricing`})"-->
        <!--        >-->
        <!--          <span class="align-middle">{{ $t('Pricing') }}</span>-->
        <!--        </b-button>-->
      </b-container>
    </b-modal>
  </div>

</template>

<script>
import {
  BButton, BModal, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { usePointsUi } from '@/views/apps/service/usePoints'
import subscriptionAlert from '@/views/apps/subscription/subscriptionAlert.vue'
import { computed, ref } from '@vue/composition-api'
import router from '@/router'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BContainer,
    subscriptionAlert,
  },

  setup() {
    const displayModal = ref(false)

    const {
      userCanAddOffer,
    } = usePointsUi()

    const canAddOffer = computed(() => userCanAddOffer())

    function addOffer() {
      if (canAddOffer.value) {
        router.push({ name: 'apps-service-offer-add-wizard' })
      } else {
        displayModal.value = true
      }
    }
    return {
      displayModal,
      addOffer,
      canAddOffer,
    }
  },
}
</script>
